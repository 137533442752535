import qs from 'query-string';
import { pipelineFilters, responseType } from '../constants';
import { TransactionType } from '../types/amr-pipeline/enums/TransactionType';
import { ExclusionReason } from '../types/amr-pipeline/models/ExcludedDealer';
import { SettlementInstructions } from '../types/amr-pipeline/models/SettlementInstructions';
import { Tranche } from '../types/amr-pipeline/models/Tranche';
import { AmrInfoSaveForm } from '../types/deals/AmrInfoSaveForm';
import { DealSave } from '../types/deals/DealSave';
import { fetchSafe } from './fetch.safe';
import { DealDocumentsWithPattern } from '../types/amr-pipeline/models/DealDocumentsWithPattern';

interface ExcludedDealersData {
    editData: {
        trancheReferenceName: string;
        brokerDealerReferenceName: string;
        reasons?: ExclusionReason[];
    }[];
}

interface GetDealTransactionsOptions {
    transactionTypes?: TransactionType[];
}

export const dealsService = {
    getDeals,
    getDeal,
    getDealRegularDocuments,
    getDealDocuments,

    getClassAuctions,
    getLatestDealAuctions,
    getDealDocumentsByType,
    getDealsList,
    getDealDocument,
    getDealsNamesList,
    getDealTransactions,
    getDealDetails,
    deleteDeal,
    updateDeal,
    updateClasses,
    getExcludedDealers,
    saveExcludedDealers,
    getAmrDocument,
    exportSettlementInstructions,
    getSettlementInstructions,
    saveSettlementInstructions,
    updateDealDocuments,
    saveAmrInfo,
    deleteAmrInfo,
    downloadAmrDocument,
    downloadAmrDocuments,
};

function getDeals() {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals`);
}

function getDeal(dealReferenceName: string) {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}`);
}

function getDealRegularDocuments(dealReferenceName: string) {
    const requestOptions = {
        responseType: responseType.file,
    };

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/documents/regular-documents/download`,
        requestOptions,
    );
}

function getDealDocuments(dealReferenceName: string) {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/documents`);
}

function getAmrDocument(dealReferenceName: string, transactionReferenceName: string) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/amr/documents`,
    );
}

function getClassAuctions(dealReferenceName: string, classReferenceName: string) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/classes/${classReferenceName}/amr-classes`,
    );
}

function getLatestDealAuctions(dealReferenceName: string) {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/latest-amr-classes`);
}

function getDealDocumentsByType(dealReferenceName: string, documentType: string) {
    const requestOptions = {
        responseType: responseType.file,
    };

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/documents/${documentType}/download`,
        requestOptions,
    );
}

function getDealsList(searchString: string, offset = 0, count = pipelineFilters.searchItemsPerLoad) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/Deals/?count=${count}&offset=${offset}&searchTerm=${searchString}`,
    );
}

function getDealDocument(dealReferenceName: string, referenceName: string) {
    const requestOptions = {
        responseType: responseType.file,
    };

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/documents/${referenceName}/download`,
        requestOptions,
    );
}

function getDealsNamesList(searchTerm: string, orderByField?: string, ascending?: boolean) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/get/list/?searchTerm=${searchTerm}&orderBy[0].field=${orderByField}&orderBy[0].ascending=${ascending}`,
    );
}

function getDealTransactions(dealReferenceName: string, options: GetDealTransactionsOptions = {}) {
    const queryParams = qs.stringify(options);

    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions?${queryParams}`);
}

function getDealDetails(dealReferenceName: string) {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/classes`);
}

function updateDeal(dealReferenceName: string, formData: DealSave) {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}`, {
        method: 'PUT',
        body: JSON.stringify(formData),
        responseType: responseType.text,
    });
}

function updateClasses(dealReferenceName: string, classes: Tranche[]) {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/classes`, {
        method: 'PUT',
        body: JSON.stringify(classes),
        responseType: responseType.none,
    });
}

function deleteDeal(dealReferenceName: string) {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}`, {
        method: 'DELETE',
        responseType: responseType.none,
    });
}

function getExcludedDealers(dealReferenceName: string, transactionReferenceName: string, classReferenceName: string) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/classes/${classReferenceName}/excluded-dealers`,
    );
}

function saveExcludedDealers(
    dealReferenceName: string,
    transactionReferenceName: string,
    classReferenceName: string,
    excludedDealersData: ExcludedDealersData,
) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/classes/${classReferenceName}/excluded-dealers`,
        {
            method: 'PUT',
            body: JSON.stringify(excludedDealersData),
            responseType: responseType.none,
        },
    );
}

function getSettlementInstructions(
    dealReferenceName: string,
    transactionReferenceName: string,
    classReferenceName: string,
) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/classes/${classReferenceName}/settlement-instructions`,
    );
}

function saveSettlementInstructions(
    dealReferenceName: string,
    classReferenceName: string,
    settlementInstructions: SettlementInstructions,
) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/${classReferenceName}/settlement-instructions`,
        {
            method: 'PUT',
            body: JSON.stringify(settlementInstructions),
            responseType: responseType.none,
        },
    );
}

function exportSettlementInstructions(
    dealReferenceName: string,
    transactionReferenceName: string,
    classReferenceName: string,
) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/classes/${classReferenceName}/settlement-instructions/download`,
        {
            responseType: responseType.file,
        },
    );
}

function saveAmrInfo(dealReferenceName: string, transactionReferenceName: string, amrInfo: AmrInfoSaveForm) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/amr`,
        {
            method: 'PUT',
            body: JSON.stringify(amrInfo),
            responseType: responseType.none,
        },
    );
}

function deleteAmrInfo(dealReferenceName: string, transactionReferenceName: string) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}`,
        {
            method: 'DELETE',
            responseType: responseType.none,
        },
    );
}

function updateDealDocuments(dealReferenceName: string, documents: DealDocumentsWithPattern) {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/documents`, {
        method: 'PUT',
        body: JSON.stringify(documents),
        responseType: responseType.none,
    });
}

function downloadAmrDocument(dealReferenceName: string, transactionReferenceName: string, documentReferenceName: string) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/amr/document/${documentReferenceName}/download`,
        {
            responseType: responseType.file,
        },
    );
}

function downloadAmrDocuments(
    dealReferenceName: string,
    transactionReferenceName: string,
) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/amr/documents/download`,
        {
            responseType: responseType.file,
        },
    );
}
